<template>
  <div v-click-outside="onBlur" class="v-input-mts">
      <input v-bind="$attrs" type="text" class="v-input-mts__input" @input="updateValue" @focus="onFocus">
      <span v-if="currentValue && isFocused" class="v-input-mts__clear" @click="clearValue"></span>
    <div v-if="!currentValue && infoText" class="v-input-mts__info">{{ infoText }}</div>
  </div>
</template>
<script>
import clickOutside from "@/directives/clickOutside";

export default {
  name: 'VInputMTS',
  directives: { clickOutside },
  inheritAttrs: false,
  props: {
   infoText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isFocused: false,
    }
  },
  computed: {
    currentValue() {
      if (this.$attrs.value) return 1
    }
  },
  methods: {
    setValue(value) {
      this.$emit('input', value);
    },
    updateValue(event) {
      this.setValue(event.target.value)
    },
    clearValue() {
      this.$attrs.value = '';
      this.setValue('')
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    onBlur() {
      this.isFocused = false;
    },
  }
}
</script>
<style lang="scss" scoped>
.v-input-mts {
  font-family: 'MTS Compact', Arial, sans-serif;
  position: relative;

  &__clear {
    display: block;
    position: absolute;
    background-image: url('~/assets/img/icons/mts/icon-mts--field-delete.svg');
    right: 12px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  &__input {
    display: inline-block;
    height: 44px;
    width: 100%;
    background: #F2F3F7;
    border: 1px solid #BCC3D080;
    border-radius: 6px;
    outline: none;
    padding: 10px 36px 10px 12px;
    box-shadow: none;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: #1D2023;
    transition: all 0.3s ease;

    &:hover {
      border-color: #1D2023;
    }

    &:focus {
      transition: all 0.3s ease;
      border-color: #007CFF;
      &::placeholder {
        color: #969FA8;
      }
    }

    &::placeholder {
      transition: all 0.3s ease;
      color: #626C77;
    }

    .xl & {
      height: 52px;
      padding: 13px 12px;
    }
  }

  &__info {
    color: $color-gray-1;
    font-family: $font-mts-compact;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
  }

  &.error {
    &:after {
      content: '';
      position: absolute;
      right: 12px;
      top: calc(50% - 12px);
      display: block;
      background-image: url('~/assets/img/icons/mts/icon-mts--field-error.svg');
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      transition: all 0.3s ease;
    }

    .v-input-mts__input {
      border-color: #F95721;
    }

     .v-input-mts__info {
      display: none;
     }
  }
}
</style>
