<template>
  <div class="v-error-mts">
    <slot></slot>
  </div>
</template>
<script>

export default {
  name: 'VErrorMTS',
}
</script>
<style lang="scss" scoped>
.v-error-mts {
  font-family: 'MTS Compact', Arial, sans-serif;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #EC3B03;
}
</style>
