<template>
  <div class="mts-form-message">
    <div class="mts-form-message__image">
      <img
        :height="resultForm.img.height"
        :src="resultForm.img.src"
        :width="resultForm.img.width"
        alt="иконка"
      />
    </div>

    <div class="mts-form-message__bottom-title">{{ resultForm.title }}</div>

    <div
      class="mts-form-message__bottom-text"
      v-html="resultForm.message"
    ></div>
  </div>
</template>

<script>
import MTSFormMessage from '@/components/Common/Form/mixins/MTSFormMessage'

export default {
  name: 'MTSFormMessage',
  mixins: [MTSFormMessage],
  props: {
    typeMessage: {
      type: String,
      default: '',
    },
    success: {
      type: Boolean,
    },
    email: {
      type: String,
    },
    // applicationWithSend: {
    //   type: Boolean,
    //   default: false,
    // },
    messageForSuccessApplication: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.mts-form-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    position: relative;
    margin-bottom: 20px;
  }

  &__bottom {
    text-align: center;
    margin: 0 auto;

    &-title {
      font-family: $font-mts-compact;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      margin-bottom: 12px;
    }

    &-text {
      font-family: $font-mts-compact;
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    &-info {
      color: #969fa8;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      font-family: $font-mts-compact;
    }
  }
}
</style>
